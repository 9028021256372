import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Buttons } from "../sdk/Button";
import { Container, Section } from "../sdk/Layout";
import InternalLink from "./InternalLink";

const Banner = styled.div`
    position: relative;
    // background-color: ${(props) => props.theme.primary};
    text-align: center;
    padding: 50px 0;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // opacity: 0.3;
    }
    ${Container} {
        // max-width: 520px;
    }
    h2 {
        font-size: 60px;
        font-weight: 800;
        color: white;
        text-wrap: balance;
        text-transform: uppercase;
    }
    ul {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: left;
        li {
            color: white;
            position: relative;
            padding-left: 20px;
            font-size: 16px;
            &:before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 15px;
                border-right: solid 2px white;
                border-bottom: solid 2px white;
                transform: rotate(45deg);
                position: absolute;
                left: 0;
                top: 0;
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 22px;
        }
    }
`;

const ButtonExtended = styled(Button)`
    height: 50px;
    padding-right: 50px;
    padding-left: 50px;
    border: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 30px;
    a {
        color: ${(props) => props.theme.primaryDark};
    }
`;

const Link = styled.div`
    text-align: center;
    margin-top: 20px;
    a {
        text-decoration: none;
        display: inline-block;
        border-bottom: dashed 1px;
        padding-bottom: 3px;
        color: white;
        font-size: 15px;
        &:hover {
            border-bottom-style: solid;
        }
    }
`;

const BannerComponent = ({ config }) => {
    if (config == null || config.length === 0) return null;
    const { img, title, cta, link, list } = config[0];

    return (
        <>
            <Banner>
                {img && img.img && img.img.length > 0 ? (
                    <LazyLoadImage src={img.img[0]} alt={img.alt} />
                ) : null}
                
                <Section>
                    <Container>
                        <h2>{title}</h2>
                        {list && list.length > 0 ? (
                            <ul>
                                {list
                                    ? list.map((index, i) => (
                                          <li key={`list-actions-items-${i}`}>
                                              {index && index.p ? index.p : ""}
                                          </li>
                                      ))
                                    : null}
                            </ul>
                        ) : null}

                        {cta ? (
                            <Buttons isCentered>
                                <a href={cta.link}>
                                    <ButtonExtended white>
                                        {cta.title}
                                    </ButtonExtended>
                                </a>
                            </Buttons>
                        ) : null}
                        {link ? (
                            <Link>
                                <InternalLink {...link} />
                            </Link>
                        ) : null}
                    </Container>
                </Section>
            </Banner>
        </>
    );
};

export default BannerComponent;
